import actionTypes from './campaign.actionTypes'

import {
  getCampaignFromCookie,
  setCampaignCookie
} from 'utils/cookies'
import { getCampaignURLParams } from 'utils/url'

import * as api from 'api/campaign'

export const saveCampaign = data => async dispatch => {
  if (!data) {
    return
  }
  const response = await api.getCampaign(data)
  response?.data && setCampaignCookie(response.data)
}

export const getCampaign = () => async dispatch => {
  const urlCampaign = getCampaignURLParams()

  if (urlCampaign) {
    await dispatch(saveCampaign(urlCampaign))
  }
  const campaign = getCampaignFromCookie()

  dispatch({
    type: actionTypes.LOAD_CAMPAIGN_DATA_ACTION,
    campaign
  })
}
