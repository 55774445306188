import _debounce from 'lodash/debounce'

import * as cookie from 'utils/cookies'

import {
  setUrlHash,
  setQueryStringParams
} from 'actions/page/url'
import { getCampaign } from 'actions/page/campaign'
import { loadLocaStorage } from 'actions/page/localStorage'
import { detectDevice, checkScreenSize } from 'actions/page/device'
import { loadUser, sodexoAuth } from 'actions/user'

export const registerHashChangeHandler = ((removeHashChangeListener = null) => dispatch => {
  if (removeHashChangeListener === null && typeof window !== 'undefined') {
    const handler = e => {
      dispatch(setUrlHash(e.newURL, e.oldURL))
    }
    window.addEventListener('hashchange', handler)
    removeHashChangeListener = () => window.removeEventListener('onhashchange', handler)

    window.location && dispatch(setUrlHash(window.location.href))
  }

  return removeHashChangeListener
})()

export const registerResizeHandler = ((removeResizeEventListener = null) => dispatch => {
  if (removeResizeEventListener === null && typeof window !== 'undefined') {
    const handler = _debounce(e => { dispatch(checkScreenSize()) }, 1000)
    window.addEventListener('resize', handler)
    removeResizeEventListener = () => window.removeEventListener('resize', handler)

    dispatch(detectDevice())
  }

  return removeResizeEventListener
})()

export const dispatchLoadLocalStorageAction = ((hasLoaded = false) => dispatch => {
  if (hasLoaded === false && typeof window !== 'undefined') {
    dispatch(loadLocaStorage())
    hasLoaded = true
  }
})()

export const initUser = (hasLoaded = false) => dispatch => {
  if (hasLoaded === false && typeof window !== 'undefined') {
    dispatch(loadUser())
    hasLoaded = true
  }
}

export const saveQueryStringParams = ((hasLoaded = false) => dispatch => {
  if (hasLoaded || typeof window === 'undefined') {
    return
  }
  dispatch(setQueryStringParams())
  hasLoaded = true
})()

export const loadCampaignData = ((hasLoaded = false) => dispatch => {
  if (hasLoaded || typeof window === 'undefined') {
    return
  }
  dispatch(getCampaign())
  hasLoaded = true
})()

export const init = () => async (dispatch, getState) => {
  dispatchLoadLocalStorageAction(dispatch)

  saveQueryStringParams(dispatch)
  loadCampaignData(dispatch)

  const soHappyData = cookie.getString(cookie.KEY.soHappyData, true)
  if (soHappyData) {
    await dispatch(sodexoAuth(soHappyData))
  }

  if (cookie.get(cookie.KEY.security)) {
    // Reset token cookie's expiration time to delay 7 days expiration limit on WebKit/Safari
    cookie.set(cookie.KEY.security, cookie.get(cookie.KEY.security))
    await dispatch(initUser())
  }

  const removeHashChangeListener = registerHashChangeHandler(dispatch)
  const removeResizeEventListener = registerResizeHandler(dispatch)

  return {
    removeHashChangeListener,
    removeResizeEventListener
  }
}
