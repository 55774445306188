export const handleScroll = (asPath) => {
  if (asPath === '/') {
    window.onscroll = function (e) {
      const scrollY = window.pageYOffset || document.documentElement.scrollTop
      const header = document.querySelector('.header')
      const smallHeader = document.querySelector('.small-header')
      const container = document.querySelectorAll('.sticky__inner')
      container.forEach((element, key) => {
        const classBase = key === 0 ? 'delivery_bar_' : 'section_nav_list_'
        if (scrollY < 65) {
          element.classList.remove(`${classBase}with_header`)
          element.classList.remove(`${classBase}without_header`)
        } else if (scrollY <= this.lastScroll) {
          element.classList.add(`${classBase}with_header`)
          element.classList.remove(`${classBase}without_header`)
        } else {
          element.classList.remove(`${classBase}with_header`)
          element.classList.add(`${classBase}without_header`)
        }
      })
      if (header) {
        scrollY < 65 ? header.className = 'header'
          : scrollY <= this.lastScroll
            ? header.className = 'header visible'
            : header.className = 'header hidden'
      }
      if (smallHeader) {
        scrollY < 65 ? smallHeader.className = 'small-header'
          : scrollY <= this.lastScroll
            ? smallHeader.className = 'small-header visible'
            : smallHeader.className = smallHeader.className.includes('visible') ? 'small-header hidden' : smallHeader.className.includes('hidden') ? smallHeader.className : 'small-header sticked'
      }

      this.lastScroll = scrollY
    }
  } else {
    window.onscroll = function (e) {
      return null
    }
  }
}
