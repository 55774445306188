export const faviconLoader = () => {
  const usesDarkMode = (process.browser && window.matchMedia('(prefers-color-scheme: dark)').matches) || false
  if (usesDarkMode === true) {
    return '/favicon-dark.svg'
  }
  if (
    process.env.REACT_APP_ENV &&
      process.env.REACT_APP_ENV.toLowerCase() === 'dev'
  ) {
    return '/favicon-dev.svg'
  }
  if (
    process.env.REACT_APP_ENV &&
      process.env.REACT_APP_ENV.toLowerCase() === 'preprod'
  ) {
    return '/favicon-preprod.svg'
  }
  // prod
  return '/favicon.svg'
}
