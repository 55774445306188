import Head from 'next/head'
import { useDispatch } from 'react-redux'
import React, { useEffect } from 'react'
import smoothscroll from 'smoothscroll-polyfill'

import { init } from 'actions/page'
import GoogleTagManager from 'components/Common/GoogleTagManager'
import { wrapper } from 'config/createStore'
import { pushToDataLayer, pushWebVitals } from 'utils/googletagmanager'
import { faviconLoader } from 'utils/favicon'

import 'styles/index.sass'
import { Favicon } from 'components/Common/Favicon'
import { useRouter } from 'next/router'
import { handleScroll } from 'utils/scroll'
import { setSessionCookie, KEY } from 'utils/cookies'

export function reportWebVitals (data) {
  pushWebVitals(data)
}

const WrappedApp = ({ Component, pageProps }) => {
  if (typeof window !== 'undefined') smoothscroll.polyfill()
  const dispatch = useDispatch()
  const faviconPath = faviconLoader()
  const { asPath, query } = useRouter()

  useEffect(() => {
    dispatch(init())
    const TRACKING_PERMISSION_KEY = 'isTrackingPermissionGranted'
    const searchParams = new URLSearchParams(window.location.search)
    if (searchParams?.has(TRACKING_PERMISSION_KEY)) {
      pushToDataLayer({ [TRACKING_PERMISSION_KEY]: searchParams.get(TRACKING_PERMISSION_KEY) })
    }
  }, [])

  useEffect(() => {
    // handle header scroll
    if (typeof window !== 'undefined') {
      handleScroll(asPath)
    }
    // Store the web app value (most likely 'ios' or 'android') if the user comes from the mobile app
    if (query?.[KEY.webApp]) {
      setSessionCookie(KEY.webApp, query[KEY.webApp])
    }
  }, [Component])

  return (
    <>
      {typeof window !== 'undefined' && (
        <Head>
          <title>FoodChéri | Livraison de déjeuners bons pour vous et pour la planète</title>
          <meta name='viewport' content='width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no' />
          <meta name='description' content='Des déjeuners bons pour la santé, bons pour la planète et bons tout court, livrés chez vous ou au bureau. Vos repas frais, cuisinés par des chefs.' />
          <meta property='og:favicon' key='favicon' rel='icon' href={faviconPath} />
          <link rel='manifest' href='/manifest.json' />
          <link rel='stylesheet' type='text/css' href='/fonts/icons.css' />
          <Favicon />
        </Head>
      )}
      <GoogleTagManager />
      <Component {...pageProps} />
    </>
  )
}
export default wrapper.withRedux(WrappedApp)
