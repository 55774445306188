import { createStore, applyMiddleware, compose } from 'redux'
import { createWrapper } from 'next-redux-wrapper'
import makeRootReducer from 'reducers'
import thunk from 'redux-thunk'

export const makeStore = (context) => {
  const middleware = [thunk]
  const enhancers = []
  const composeEnhancers = (typeof window !== 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose
  const store = createStore(
    makeRootReducer(),
    composeEnhancers(
      applyMiddleware(...middleware),
      ...enhancers
    ))

  if (module.hot) {
    module.hot.accept('reducers', () => {
      console.log('Replacing reducer')
      store.replaceReducer(require('reducers').default)
    })
  }

  return store
}

export const wrapper = createWrapper(makeStore, { debug: false })
